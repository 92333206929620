<template>
  <v-row>
    <v-col cols="12" v-if="editing" class="pt-0">
      <v-card flat>
        <v-card-title v-text="title" class="text-overline px-0" />

        <v-card-text class="pa-0">
          <v-row>
            <!-- Old -->
            <v-col cols="12">
              <v-text-field
                v-model="passwordOld"
                :append-icon="iconOld"
                :type="fieldTypeOld"
                @click:append="showOld = !showOld"
                label="Old password"
                placeholder="Enter your old password"
                :loading="loading"
                :error-messages="errorsOld"
                @input="$v.passwordOld.$touch()"
                @blur="$v.passwordOld.$touch()"
                autocomplete="new-password"
                counter
                dense
                required
              />
            </v-col>

            <!-- New -->
            <v-col cols="12">
              <v-text-field
                v-model="passwordNew"
                :append-icon="iconNew"
                :type="fieldTypeNew"
                @click:append="showNew = !showNew"
                label="New password"
                placeholder="Enter your new password"
                :loading="loading"
                :error-messages="errorsNew"
                @input="$v.passwordNew.$touch()"
                @blur="$v.passwordNew.$touch()"
                autocomplete="new-password"
                counter
                dense
                required
              />
            </v-col>

            <!-- Confirm -->
            <v-col cols="12">
              <v-text-field
                v-model="passwordAgain"
                :append-icon="iconAgain"
                :type="fieldTypeAgain"
                @click:append="showAgain = !showAgain"
                label="Confirm new password"
                placeholder="Confirm your new password"
                :loading="loading"
                :error-messages="errorsAgain"
                @input="$v.passwordAgain.$touch()"
                @blur="$v.passwordAgain.$touch()"
                autocomplete="new-password"
                counter
                dense
                required
              />
            </v-col>

            <v-col cols="12" class="text-right">
              <v-btn
                color="success"
                depressed
                small
                :loading="loading"
                @click="validateAndUpdate"
              >
                Update password
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, minLength, sameAs } from 'vuelidate/lib/validators'
import { containsNumeric } from '@/utils/mixins/forms/validators'

export default {
  name: 'pibot-settings-password-reset-user',
  mixins: [validationMixin],
  validations: {
    passwordOld: { required },
    passwordNew: {
      required,
      minLength: minLength(6),
      containsNumeric
    },
    passwordAgain: { required, sameAsNew: sameAs('passwordNew') }
  },
  data: () => ({
    title: 'Password reset',
    passwordOld: '',
    passwordNew: '',
    passwordAgain: '',
    showOld: false,
    showNew: false,
    showAgain: false
  }),
  computed: {
    ...mapState({
      editing: state => state.settings.editing,
      loading: state => state.settings.loading
    }),
    iconOld () {
      return this.showOld ? 'visibility_off' : 'visibility'
    },
    iconNew () {
      return this.showNew ? 'visibility_off' : 'visibility'
    },
    iconAgain () {
      return this.showAgain ? 'visibility_off' : 'visibility'
    },
    fieldTypeOld () {
      return this.showOld ? 'text' : 'password'
    },
    fieldTypeNew () {
      return this.showNew ? 'text' : 'password'
    },
    fieldTypeAgain () {
      return this.showAgain ? 'text' : 'password'
    },
    errorsOld () {
      const errors = []
      if (!this.$v.passwordOld.$dirty) return errors
      !this.$v.passwordOld.required && errors.push('Old password is required.')
      return errors
    },
    errorsNew () {
      const errors = []
      if (!this.$v.passwordNew.$dirty) return errors
      !this.$v.passwordNew.required && errors.push('New password is required.')
      !this.$v.passwordNew.minLength && errors.push('New password needs to have a minimum length of 6.')
      !this.$v.passwordNew.containsNumeric && errors.push('New password needs to have a number in it.')
      this.$v.passwordAgain.$dirty && !this.$v.passwordAgain.sameAsNew && errors.push('Passwords don\'t match.')
      return errors
    },
    errorsAgain () {
      const errors = []
      if (!this.$v.passwordAgain.$dirty) return errors
      !this.$v.passwordAgain.required && errors.push('Confirm your new password.')
      !this.$v.passwordAgain.sameAsNew && errors.push('Passwords don\'t match.')
      return errors
    }
  },
  methods: {
    ...mapActions({
      tryPasswordUpdate: 'settings/socket_tryPasswordUpdate'
    }),
    validate () {
      this.$v.$touch()
      return !this.$v.$invalid
    },
    validateAndUpdate () {
      const isValid = this.validate()
      if (!isValid) return

      const payload = {
        _id: this.editing._id,
        passwordOld: this.passwordOld,
        passwordNew: this.passwordNew,
        passwordAgain: this.passwordAgain
      }

      this.$emit('loading', true)

      this.tryPasswordUpdate(payload)
        .then(() => {
          this.$emit('success', 'Password successfully updated')
          this.$emit('error', false)
          this.passwordOld = ''
          this.passwordNew = ''
          this.passwordAgain = ''
          this.$v.$reset()
        })
        .catch(error => {
          this.$emit('success', false)
          this.$emit('error', error.message)
        })
        .finally(() => {
          this.$emit('loading', false)
        })
    }
  }
}
</script>
